<template lang="html">
  <div class="main">
    <div class="top">
      <div class="top_container">
        <div class="text_line">
          <div class="lined_text">
            <h2 class="text" v-html="description"></h2>
            <hr class="long" />
          </div>
          <h1 v-html="title"></h1>
        </div>

        <div class="contact">
          <img src="../assets/mail.svg" id="mail_img" /><a
            href="mailto:k3@kreps-grafik.de"
            id="mail_text"
            >k3@kreps-grafik.de</a
          >
          <br />
          <img src="../assets/phone.svg" id="phone_img" /><a
            href="tel:+49-160-9706340"
            id="phone_text"
            >+49 160 97063401</a
          >
        </div>
      </div>
      <img
        id="world"
        :src="`http://kreps-grafik.de/images/${data.animation.path}`"
        alt=""
      />
    </div>
    <main>
      <a href="print">
        <JumpingLetters word="Print." />
      </a>
      <a href="digital">
        <JumpingLetters word="Digital." />
      </a>
      <a href="packaging">
        <JumpingLetters word="Packaging." />
      </a>
      <a href="film">
        <JumpingLetters word="Film." />
      </a>

      <div class="description-text">
        <hr />
        <div class="text">
          <p class="main-text" v-html="data.description" />
          <p class="main-text">
            Erfahren Sie mehr <a href="about">Über mich</a>
          </p>
        </div>
      </div>

      <div class="projects_title">
        <h2 class="text">Ein Überblick meiner neuesten</h2>
        <h1>Projekte</h1>
      </div>
      <div class="projects">
        <div class="element" v-for="project in projects" :key="project._id">
          <a :href="`project/${project.url}`">
            <img :src="`http://kreps-grafik.de${project.Image.path}`" alt="" />
            <p>
              <strong>{{ project.Customer }} _ </strong
              >{{ project.Short_description }}
            </p>
          </a>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CMSService from "../CMS-Service.js";
import JumpingLetters from "../components/jumpingLetters.vue";
export default {
  components: {
    JumpingLetters,
  },
  data() {
    return {
      data: new Object(),
      title: "Katharina Kreps<br />kreativdesign",
      description: "Hallo, ich bin",
      projects: new Array(),
    };
  },
  created() {
    CMSService.getCollectionEntries("Startseite").then((data) => {
      this.data = data.data.entries[0];
    });
    CMSService.getCollectionEntries("Projekte").then((data) => {
      for (var i = 0; i < data.data.entries.length; i++) {
        if (data.data.entries[i].mainpage == true) {
          this.projects.push(data.data.entries[i]);
        }
      }
    });
  },
  mounted() {
    var i = 0;
    setInterval(() => {
      var texts = [
        "Katharina Kreps<br />Kreativdesign",
        "kleiner<br />Wirbelwind",
      ];
      var descriptions = ["Hallo, ich bin", "Und das ist mein"];
      i = i + 1;
      this.title = texts[i % 2];
      this.description = descriptions[i % 2];
    }, 5500);
  },
};
</script>

<style lang="scss" scoped>
@import "../main.scss";

.top {
  background-color: white;
  padding: 0 33px;
  width: calc(100% - 66px);

  .top_container {
    position: relative;
    margin: auto;
    max-width: 1920px;
  }

  .text_line {
    max-width: 450px;
    margin-top: 30px;
  }

  .contact {
    z-index: 2;
    position: absolute;
    right: 0px;
    margin-top: 50px;

    #mail_img,
    #phone_img {
      width: 18px;
      filter: invert(1);
    }
    #mail_text {
      font-family: "PT Sans";
      font-size: 20px;
      font-weight: regular;
      line-height: 24px;
      text-decoration: none;
      color: $k3-black;
      margin-left: 22px;
    }

    #phone_img {
      position: absolute;
      margin-top: 27px;
    }

    #phone_text {
      writing-mode: vertical-rl;
      font-family: droid-serif, serif;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: $k3-black;
      margin-left: 22px;
      margin-top: 27px;
    }
  }

  #world {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1920px;
    left: 50%;
    transform: translate(-50%, 4px);
    //clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  }
}
.main {
  background: $k3-black;

  main {
    max-width: 1250px;
    margin: auto;
    //transform: translateY(calc(calc(calc(100vw - 66px) / 2) * -1));
    background: $k3-black;
    z-index: 5;
    padding: 0 10%;
    padding-top: 25px;

    .description-text {
      margin-top: 76px;
      hr {
        display: inline-block;
        width: 176px;
        margin-right: 30px;
        vertical-align: top;
        border: none;
        border: 1px solid $k3-red;
        margin-top: 16px;
      }
      .text {
        display: inline-block;
        width: calc(100% - 176px - 66px);

        .main-text {
          margin-top: 10px;
          color: $k3-gray;
          a {
            color: white;
            font-weight: bold;
            text-decoration-color: $k3-red;
          }
          strong {
            color: white;
          }
        }
      }
    }

    .projects_title {
      color: white;
      text-align: right;
      margin-top: 100px;

      h1 {
        position: relative;
      }

      h1::before {
        content: "";
        display: block;
        width: 170px;
        height: 2px;
        background: $k3-red;
        top: 75%;
        position: absolute;
        right: 250px;
      }
    }

    .projects {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 78px 0;
      .element {
        width: 25vw;
        min-width: 310px;
        max-width: 400px;

        margin: 19px 19px;

        img {
          width: 100%;
        }
        p {
          color: white;
        }
      }
    }
  }
}

//mobile
@media screen and (max-width: 720px) {
  .text_line {
    max-width: 220px !important;
    margin-top: 0px !important;
  }
  .projects_title {
    margin-top: 50px !important;

    h1::before {
      width: 80px !important;
      right: 125px !important;
    }
  }
  .contact {
    display: none;

    #world {
      transform: translate(-50%) !important;
    }
  }
  .description-text {
    margin-top: 25px !important;

    hr {
      display: none !important;
    }
    .text {
      width: 100% !important;
    }
  }
}
</style>
