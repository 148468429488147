<template lang="html">
  <div class="jumping_word">
    <h1
      v-for="letter in word"
      :key="letter + Math.random() * 101"
      class="letter"
    >
      {{ letter }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    word: String(),
  },
  data() {
    return {
      print: "Print.",
      digital: "Digit",
    };
  },
  mounted() {
    var n = Array.from(document.querySelectorAll(".letter"));
    n &&
      n.forEach(function (e) {
        e.addEventListener(
          "mouseover",
          function () {
            this.classList.add("jumpingLetter");
          },
          !1
        ),
          e.addEventListener(
            "animationend",
            function () {
              this.classList.remove("jumpingLetter");
            },
            !1
          );
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../main.scss";

h1 {
  color: white;
  cursor: pointer;
  font-size: 150px;
  font-weight: 700;
}

.jumping_word {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  display: inline-block;
  margin-right: 30px;
}

.letter {
  transition: 0.75s cubic-bezier(0.175, 0.885, 0.32, 2.275) 1.35s;
  float: left;
  will-change: opcity, transform;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform-origin: 50% 100%;
  will-change: text-shadow, transform;
}

.jumpingLetter {
  animation: c 1.5s 1 alternate forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes c {
  0%,
  to {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  25%,
  75% {
    text-shadow: -5px 2px 2px rgba(0, 0, 0, 0.3), 0 5px 12px rgba(0, 0, 0, 0.3);
    transform: matrix(1.1, 0, 0, 0.8, 0, 0);
  }

  50% {
    transform: matrix(0.8, 0, 0, 1.2, 0, 0) translateY(-10px);
    text-shadow: -40px 20px 20px rgba(0, 0, 0, 0.3),
      0 40px 100px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 720px) {
  .jumping_word {
    margin-right: 10px;
  }
  h1 {
    font-size: 40px;
  }
}
</style>
